import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/lib/utils/cn';
import { Loader2 } from 'lucide-react';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full text-base font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        brand: 'bg-brand text-brand-foreground hover:bg-brand/90',
        'brand-outline':
          'border border-brand bg-background text-brand hover:bg-accent',
        'brand-accent':
          'bg-brand-accent text-brand-accent-foreground hover:bg-brand-accent/90',
        'brand-accent-outline':
          'border border-brand-accent bg-background text-brand-accent hover:bg-accent',
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive:
          'bg-destructive text-destructive-foreground hover:bg-destructive/90',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        'ghost-brand':
          'hover:bg-brand-foreground dark:hover:bg-brand-900 text-brand hover:text-brand',
        link: 'px-0 text-primary font-normal text-base transition-all hover:text-brand',
      },
      size: {
        default: 'h-10 px-4 py-2',
        sm: 'h-9 rounded-full px-3',
        lg: 'h-11 rounded-full px-8',
        icon: 'h-10 w-10',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  loadingChildren?: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      loading,
      disabled,
      children,
      asChild = false,
      loadingChildren,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    return (
      <Comp
        className={cn(
          loading && 'relative overflow-hidden',
          buttonVariants({ variant, size, className })
        )}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading && !!loadingChildren ? undefined : children}

        {loading &&
          (loadingChildren ? loadingChildren : (
            <div className='absolute inset-0 flex items-center justify-center bg-inherit z-10'>
              <Loader2 className='text-inherit animate-spin' />
            </div>
          ))}
      </Comp>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };
